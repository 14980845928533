<template>
    <div></div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { confirmRegister } from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import router from '@/router/index.ts'
export default defineComponent({
    setup() {
        const store = useStore()
        const state = reactive({
            memberIdStr: ''
        })
        async function registerConfirm() {
            if (!state.memberIdStr) return
            const { data } = await confirmRegister({
                memberIdStr: state.memberIdStr
            })
            if (data.success) {
                ElMessage.success(data.message)
                store.commit('setMemberIdStr', state.memberIdStr)
                setTimeout(() => {
                    router.push('/')
                }, 2000)
            } else {
                ElMessage.error(data.message)
            }
        }
        onMounted(() => {
            state.memberIdStr = router.currentRoute.value.query.memberIdStr
            registerConfirm()
        })
        return {
            ...toRefs(state),
            registerConfirm
        }
    }
})
// export default {
//     data() {
//         return {
//             memberIdStr: ''
//         }
//     },
//     methods: {
//         async registerConfirm() {
//             if (!this.memberIdStr) return
//             const { data } = await confirmRegister({
//                 memberIdStr: this.memberIdStr
//             })
//             if (data.success) {
//                 ElMessage.success(data.message)
//                 setTimeout(() => {
//                     this.$router.push('/') //replace
//                 }, 2000)
//             } else {
//                 ElMessage.error(data.message)
//             }
//         }
//     },
//     created() {
//         this.memberIdStr = this.$route.query.memberIdStr
//         this.registerConfirm()
//     }
// }
</script>

<style>
</style>